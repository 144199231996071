import React from 'react';

export const DePay = (props) => {
    return (
        <div id="payment" className="text-center">
            <div className="container">
                <div className="section-title">
                    <h2>
                        {props.data.title}
                    </h2>
                </div>
                <div className="col-md-8 col-md-offset-2">
                    <p>{props.data.text}</p>
                    <h3>{props.data.duration} = {props.data.price}</h3>
                    <a
                        href={props.data.link}
                        className="payment-button"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        💳 Pay Now
                    </a>
                    <style jsx>{`
                    .payment-button {
                    background: linear-gradient(45deg, #ff5f6d, #ffc371);
                    border: none;
                    border-radius: 25px;
                    padding: 10px 20px;
                    color: #fff;
                    font-size: 1.2em;
                    font-weight: bold;
                    cursor: pointer;
                    transition: transform 0.3s ease, box-shadow 0.3s ease;
                    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                    text-decoration: none;
                    display: inline-block;
                    }
                    .payment-button:hover {
                    transform: scale(1.05);
                    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
                    color: #fff;
                    }
                    .payment-button:visited {
                    color: #fff; /* Ensure text color remains white after visiting */
                    }
                    .payment-button:disabled {
                    background: #ccc;
                    cursor: not-allowed;
                    }
                    .error-message {
                    color: #ff5f6d;
                    margin-top: 10px;
                    }
                    `}</style>
                </div>
            </div>
        </div>
    );
};
