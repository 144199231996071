import { useState } from "react";
import HomePage from "./components/home";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { Gallery } from "./components/gallery";
import { Contact } from "./components/contact";
import Aoe2Data from "./data/aoe2.json";
import Coh3Data from "./data/coh3.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import { DePay } from "./components/Depay";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const Aoe2dePage = () => {
  return (
    <div id="intro" style={{
      backgroundImage: `url(../img/aoe2intro.jpeg)`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    }}>
      <Navigation />
      <Header data={Aoe2Data.Header} />
      <Features data={Aoe2Data.Features} />
      <Gallery data={Aoe2Data.Gallery} />
      <DePay data={Aoe2Data.Depay} />
      <Contact data={Aoe2Data.Contact} />
    </div>
  );
};

const Coh3Page = () => {
  return (
    <div id="intro" style={{
      backgroundImage: `url(../img/coh3intro.jpeg)`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    }}>
      <Navigation />
      <Header data={Coh3Data.Header} />
      <Features data={Coh3Data.Features} />
      <Gallery data={Coh3Data.Gallery} />
      <DePay data={Coh3Data.Depay} />
      <Contact data={Coh3Data.Contact} />
    </div>
  );
};

const App = () => {
  const [currentPage, setCurrentPage] = useState("home");

  const renderPage = () => {
    switch (currentPage) {
      case "aoe2de":
        return <Aoe2dePage />;
      case "coh3":
        return <Coh3Page />;
      default:
        return <HomePage setCurrentPage={setCurrentPage} />;
    }
  };

  return (
    <div>
      {renderPage()}
    </div>
  );
};

export default App;
