import React from 'react';

const HomePage = ({ setCurrentPage }) => {
  return (
    <div id="intro" className="homepage">
      <h1>Select a Game</h1>
      <div className="game-links">
        <button className="game-link" onClick={() => setCurrentPage("aoe2de")}>Age of Empires II: DE</button>
        <button className="game-link" onClick={() => setCurrentPage("coh3")}>Company of Heroes 3</button>
      </div>
    </div>
  );
};

// Updated dark mode styles
const styles = `
  body {
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: #1e1e2f;
    color: #e0e0e0;
  }

  .homepage {
    text-align: center;
    padding: 50px;
  }

  h1 {
    font-size: 2.5rem;
    margin-bottom: 30px;
    color: #ffffff;
  }

  .game-links {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
  }

  .game-link {
    display: inline-block;
    margin: 10px;
    padding: 15px 30px;
    font-size: 1rem;
    font-weight: bold;
    color: #1e1e2f;
    background-color: #4caf50;
    text-decoration: none;
    border-radius: 8px;
    transition: all 0.3s ease;
  }

  .game-link:hover {
    background-color: #388e3c;
    color: #ffffff;
    transform: scale(1.05);
  }
`;

// Inject styles into the document head
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default HomePage;
